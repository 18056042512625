@font-face {
  font-family: 'DINNextBlack';
  src: url('../font/DINNextLTW23-Black.woff') format('woff'),
       url('../font/DINNextLTW23-Black.ttf') format('truetype'),
       url('../font/DINNextLTW23-Black.svg') format('svg');
}

@font-face {
  font-family: 'DINNextBold';
  src: url('../font/DINNextLTW23-Bold2.woff') format('woff'),
       url('../font/DINNextLTW23-Bold2.ttf') format('truetype'),
       url('../font/DINNextLTW23-Bold2.svg') format('svg');
}

@font-face {
  font-family: 'DINNextHeavy';
  src: url('../font/DINNextLTW23-Heavy.woff') format('woff'),
       url('../font/DINNextLTW23-Heavy.ttf') format('truetype'),
       url('../font/DINNextLTW23-Heavy.svg') format('svg');
}

@font-face {
  font-family: 'DINNextLight';
  src: url('../font/DINNextLTW23-Light.woff') format('woff'),
       url('../font/DINNextLTW23-Light.ttf') format('truetype'),
       url('../font/DINNextLTW23-Light.svg') format('svg');
}

@font-face {
  font-family: 'DINNextMedium';
  src: url('../font/DINNextLTW23-Medium.woff') format('woff'),
       url('../font/DINNextLTW23-Medium.ttf') format('truetype'),
       url('../font/DINNextLTW23-Medium.svg') format('svg');
}

@font-face {
  font-family: 'DINNextRegular';
  src: url('../font/DINNextLTW23-Regular.woff') format('woff'),
       url('../font/DINNextLTW23-Regular.ttf') format('truetype'),
       url('../font/DINNextLTW23-Regular.svg') format('svg');
}

@font-face {
  font-family: 'DINNextUltraLight';
  src: url('../font/DINNextLTW23-UltraLight.woff') format('woff'),
       url('../font/DINNextLTW23-UltraLight.ttf') format('truetype'),
       url('../font/DINNextLTW23-UltraLight.svg') format('svg');
}

